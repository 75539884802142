.column {
  flex-basis: 200px;
}

.title {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  font-size: 20px;
  line-height: 24px;
  padding: 10px 16px;
}

.linksList {
  line-height: 24px;
  list-style-type: none;
  margin: 0;
  padding-left: 16px;
  text-decoration: none;
}

.link {
  color: white;
  margin-top: 16px;
  text-decoration: none;

  &:hover {
    opacity: 0.5;
  }
}

.item {
  margin-top: 16px;
}
