.container {
  align-self: center;
  align-items: center;
  color: white;
  justify-content: space-between;
  max-width: 860px;
  margin-top: var(--space-160);
  width: 100%;
  flex: 1;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
}

.message {
  align-items: center;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
}

.descriptionContainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 0;
  text-align: left;

  @media screen and (max-width: 1024px) {
    align-items: center;
    margin-top: 30px;
    text-align: center;
  }
}

.actions {
  display: flex;
  flex-flow: row nowrap;
  margin-top: 44px;

  @media screen and (min-width: 1024px) {
    margin-top: 28px;
  }
}

.title {
  font-size: 32px;
  font-weight: 300;
  margin: 0;

  @media screen and (min-width: 1024px) {
    font-size: 58px;
  }
}

.description {
  font-size: 16px;
  margin-top: 12px;
  max-width: 230px;

  @media screen and (min-width: 1024px) {
    font-size: 22px;
    margin-top: 15px;
    max-width: 390px;
  }
}

.navigation {
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-top: var(--space-240);

  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.actionButton {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  border: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  font-size: 16px;
  outline: none;
  padding: 8px 14px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }
}

.square {
  align-items: center;
  background-color: #ba4a45;
  display: flex;
  flex-direction: column;
  font-size: 19px;
  height: 150px;
  justify-content: center;
  text-shadow: 3.5px 3.5px 0 rgba(0, 0, 0, 0.35);
  text-transform: uppercase;
  width: 150px;

  @media screen and (min-width: 900px) {
    width: 250px;
    height: 250px;
    font-size: 32px;
  }

  @media (orientation: landscape) {
    margin-right: 30px;
  }
}

.code {
  font-size: 36px;
  text-shadow: 4.5px 4.5px 0 rgba(0, 0, 0, 0.35);

  @media screen and (min-width: 1024px) {
    font-size: 60px;
  }
}
